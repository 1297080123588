const mobile = [
    {
        path: '/mobile/recharge.htm',
        name: 'mobilerecharge',
        meta: { isAuth: true},
        component: () => import('../../views/wx/mobile/recharge.vue'),
    },
    {
        path: '/mobile/success.htm',
        name: 'mobilesuccess',
        meta: { isAuth: true },
        component: () => import('../../views/wx/mobile/success.vue'),
    }
]

export default mobile